import * as React from 'react'
import Layout from '../../components/Layout/Layout'
import Banner from '../../components/Banner/Banner'
import Button from '../../components/Button/Button'
import Title from '../../components/Title/Title'
import Text from '../../components/Text/Text'
import Underline from '../../components/Underline/Underline'
import { Helmet } from 'react-helmet'

function ShoulderPain() {
    return (
        <Layout>
            <Helmet>
                <title>Shoulder Pain - Conditions we treat | Brixworth Osteopathic Clinic</title>
            </Helmet>
            <Title blue={true}>
                <h1>Shoulder Pain</h1>
            </Title>
            <section>
                <Text>
                    <p>Shoulder pain is common and can be caused by a number of conditions, including:</p>
                    <ul>
                        <li><strong>Rotator cuff problem </strong> - pain in the shoulder or upper arm, particularly when lifting the arm, lying on it or using the sore muscles. It is often the result of repetitive overuse of the arm and shoulder during a sport or activity or the result of a shoulder injury. Age can also play a part.</li>
                        <li><strong>Acromioclavicular joint pain</strong> - painful joint on the tip of the shoulder where the collarbone and shoulder blade join.</li>
                        <li><strong>Frozen shoulder or adhesive capsulitis</strong> - is the painful and gradual stiffening of the shoulder capsule (the tissue surrounding the shoulder joint) and the shoulder often becomes so stiff and painful that it limits ability to use the arm in everyday activities.</li>
                        <li><strong>Referred shoulder pain</strong> - pain is experienced in an area away from the injury or problem e.g. pain in the shoulder, usually referred from the neck or upper back.</li>
                        <li><strong>Osteoarthritis</strong> - progressive wearing away of the cartilage of the joint leading to the two bones of the joint rubbing together causing pain. Patients who have had previous trauma or shoulder surgery are most likely to develop osteoarthritis in later life. Symptoms include swelling, stiffness, aching and sharp, stabbing pains.</li>
                        <li><strong>Shoulder instability</strong> - dislocation or excessive movement of the shoulder joint.</li>
                    </ul>
                </Text>
                <Text>
                    <Underline>
                        <h2>How Can an Osteopath Help?</h2>
                    </Underline>
                    <p>Shoulder problems are often complex and can take time to resolve. An osteopath will work with you to try and understand the cause.</p>
                    <p>Depending on your age, fitness and diagnosis, a variety of gentle hands-on techniques may be used to improve the movement in the shoulder and reduce tension in any tight muscles.</p>
                    <p>Management and treatment approaches will be different for every individual and will often take a whole-body approach. Techniques may be used to loosen the joints of the neck and upper and mid-back and shoulder blade area and sometimes the low back and hips if it is felt they are contributing to shoulder pain.</p>
                    <p>Specific strengthening or loosening exercises to the shoulder may be offered, along with advice on posture. How the shoulder is used may also be looked at and any lifestyle habits that could contribute to shoulder problems.</p>
                    <p>X-rays, scans or other tests may be required to make a diagnosis and a referral made to a GP for any further treatment.</p>
                </Text>
            </section>
            <section style={{backgroundColor: 'var(--primary-800)', color: 'white'}}>
                <Banner>
                    <h2>Make an appointment with us.</h2>
                    <Button external={true} type="neutralOutline" to="https://eubookings.nookal.com/bookings/location/GGNJC">Book online today</Button>
                </Banner>
            </section>
        </Layout>
    )
}

export default ShoulderPain